import React from "react";
import { NavLink } from "react-router-dom";
import img1 from "../assets/img/team/team-1.jpg";
import img2 from "../assets/img/team/team-2.jpg";
import img3 from "../assets/img/team/team-3.jpg";
import img4 from "../assets/img/team/team-4.jpg";

export const About = () => {
  return (
    <section id="team" className="team">
      <div className="container">
        <div className="section-title mt-4" data-aos="fade-up">
          <p className="mt-4">Our team is always here to help</p>
        </div>

        <div>
          <p className="fs-5 text">
            Welcome to 2Koders Private Limited, where innovation meets
            excellence in IT solutions. We specialize in crafting cutting-edge
            Android, iOS, and web applications, as well as robust reputation
            management software. With a focus on delivering quality results
            tailored to our clients' needs, we leverage a diverse range of
            technologies including Flutter, Laravel, React, Node.js, and
            ASP.NET.
          </p>
          <p className="fs-5 text">
            At 2Koders Private Limited, we believe that every project is an
            opportunity to create something exceptional. Our team of seasoned
            professionals brings together a wealth of experience and expertise
            to tackle even the most complex challenges. Whether it's developing
            a sleek mobile app, a responsive web platform, or a powerful
            reputation management tool, we thrive on pushing the boundaries of
            what's possible.
          </p>
          <p className="fs-5 text">
            We understand that success in today's digital landscape requires
            more than just technical proficiency. That's why we prioritize
            collaboration, communication, and transparency throughout every
            stage of the development process. From initial concept to final
            delivery, we work closely with our clients to ensure their vision is
            brought to life with precision and care.
          </p>
          <p className="fs-5 text">
            What sets us apart is our commitment to continuous improvement and
            innovation. We stay ahead of the curve by staying up-to-date with
            the latest trends and advancements in technology, allowing us to
            deliver solutions that not only meet but exceed expectations.
          </p>
          <p className="fs-5 text">
            Whether you're a startup looking to disrupt the market or an
            established enterprise seeking to optimize your operations, 2Koders
            Private Limited is here to help you succeed. Let us be your trusted
            partner on the journey to digital transformation.
          </p>
          <p className="fs-5 text">
            Contact us today to learn more about how we can turn your ideas into
            reality.
          </p>
        </div>

        {/* <div className="row">
          <div
            className="col-xl-3 col-lg-4 col-md-6"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div className="member">
              <img src={img1} className="img-fluid" alt="" />
              <div className="member-info">
                <div className="member-info-content">
                  <h4>Walter White</h4>
                  <span>Chief Executive Officer</span>
                </div>
                <div className="social">
                  <NavLink>
                    <i className="bi bi-twitter"></i>
                  </NavLink>
                  <NavLink>
                    <i className="bi bi-facebook"></i>
                  </NavLink>
                  <NavLink>
                    <i className="bi bi-instagram"></i>
                  </NavLink>
                  <NavLink>
                    <i className="bi bi-linkedin"></i>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="text-center">
              <h3 className="text-danger fs-2 text">Co Founder</h3>
              <span className="fs-6 text">
                He is good boy and passionated hard working person
              </span>
            </div>
          </div>

          <div
            className="col-xl-3 col-lg-4 col-md-6"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div className="member">
              <img src={img2} className="img-fluid" alt="" />
              <div className="member-info">
                <div className="member-info-content">
                  <h4>Sarah Jhonson</h4>
                  <span>Product Manager</span>
                </div>
                <div className="social">
                  <NavLink>
                    <i className="bi bi-twitter"></i>
                  </NavLink>
                  <NavLink>
                    <i className="bi bi-facebook"></i>
                  </NavLink>
                  <NavLink>
                    <i className="bi bi-instagram"></i>
                  </NavLink>
                  <NavLink>
                    <i className="bi bi-linkedin"></i>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="text-center">
              <h3 className="fs-2 text text-danger ">Co Founder</h3>
              <span className="fs-6 text">
                He is good boy and passionated hard working person
              </span>
            </div>
          </div>

          <div
            className="col-xl-3 col-lg-4 col-md-6"
            data-aos="zoom-in"
            data-aos-delay="300"
          >
            <div className="member">
              <img src={img3} className="img-fluid" alt="" />
              <div className="member-info">
                <div className="member-info-content">
                  <h4>William Anderson</h4>
                  <span>CTO</span>
                </div>
                <div className="social">
                  <NavLink>
                    <i className="bi bi-twitter"></i>
                  </NavLink>
                  <NavLink>
                    <i className="bi bi-facebook"></i>
                  </NavLink>
                  <NavLink>
                    <i className="bi bi-instagram"></i>
                  </NavLink>
                  <NavLink>
                    <i className="bi bi-linkedin"></i>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="text-center">
              <h3 className="text-danger fs-2 text">CEO</h3>
              <span className="text-dark fs-6 text">
                He is good boy and passionated hard working person
              </span>
            </div>
          </div>

          <div
            className="col-xl-3 col-lg-4 col-md-6"
            data-aos="zoom-in"
            data-aos-delay="400"
          >
            <div className="member">
              <img src={img4} className="img-fluid" alt="" />
              <div className="member-info">
                <div className="member-info-content">
                  <h4>Amanda Jepson</h4>
                  <span>Accountant</span>
                </div>
                <div className="social">
                  <NavLink>
                    <i className="bi bi-twitter"></i>
                  </NavLink>
                  <NavLink>
                    <i className="bi bi-facebook"></i>
                  </NavLink>
                  <NavLink>
                    <i className="bi bi-instagram"></i>
                  </NavLink>
                  <NavLink>
                    <i className="bi bi-linkedin"></i>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="text-center">
              <h3 className="text-danger fs-2 text">CTO</h3>
              <span className="text-dark fs-6 text">
                He is good boy and passionated hard working person
              </span>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};
