import React from "react";
import Carousel from "./Carousel";
import img2 from "../assets/img/gym.jpg";
import img3 from "../assets/img/gym-dash.jpg";
import img1 from "../assets/img/gym-manager.jpg";

// Define project data
const projects = [
  {
    name: "Gym Manager",
    image: img1,
    details: "Credentials : aman/aman, admin/Admin@123",
    url: "https://gym-manager.2koders.com/",
  },
  {
    name: "Gym Manager",
    image: img2,
    details: "Credentials : aman/aman, admin/Admin@123",
    url: "https://gym-manager.2koders.com/",
  },
  {
    name: "Gym Manager",
    image: img3,
    details: "Credentials : aman/aman, admin/Admin@123",
    url: "https://gym-manager.2koders.com/",
  },
];

export const Product = () => {
  return (
    <section id="contact" className="contact text-center">
      <h4 className="">
        <span className="explore-projects">Explore our projects</span>
        <br></br>
      </h4>
      <Carousel projects={projects} />
      <span className="pt-4" style={{ color: "#eb5d1e" }}>
        Click on the image to view project demo
      </span>
    </section>
  );
};
