import React from "react";
import { NavLink } from "react-router-dom";

export const HomeSection = () => {
  return (
    <div>
      <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
              <h1>Bettter digital experience with 2Koders</h1>
              <h2>
                We are team of talented designers, who serves Web Services
              </h2>
              <div>
                <NavLink to="contact" className="btn-get-started scrollto">
                  Get Started with us
                </NavLink>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2 hero-img">
              <img
                src="assets/img/hero-img.svg"
                className="img-fluid animated"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
