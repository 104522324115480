import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/img/logo.jpg";

export const Navbar = () => {
  return (
    <div>
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex align-items-center justify-content-between">
          <div className="logo">
            <h1 className="text-light">
              <NavLink to="/">
                <span className="text-dark" id="mainTitle">
                  <img src={logo} alt="" className="logo" />
                  {/* <span className="text-danger">2</span>KODERS */}
                </span>
              </NavLink>
            </h1>
          </div>

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <NavLink to="/" className="nav-NavLink scrollto ">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="product" className="nav-NavLink scrollto ">
                  Products
                </NavLink>
              </li>
              <li>
                <NavLink to="about" className="nav-NavLink scrollto">
                  About Us
                </NavLink>
              </li>
              <li>
                <NavLink to="services" className="nav-NavLink scrollto">
                  Services
                </NavLink>
              </li>
              <li>
                <NavLink to="contact" className="nav-NavLink scrollto">
                  Contact
                </NavLink>
              </li>
              <li>
                <NavLink to="contact" className="getstarted scrollto">
                  Get Started
                </NavLink>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
        </div>
      </header>
    </div>
  );
};
