import axios from "axios";

export const get = (url) => {
  return axios
    .get(url)
    .then((response) => {
      // Return the response data
      return response.data;
    })
    .catch((error) => {
      // Throw the error for handling in the calling code
      throw error;
    });
};
export const post = (url, obj) => {
  return axios
    .post(url, obj)
    .then((response) => {
      // Return the response data
      return response.data;
    })
    .catch((error) => {
      // Throw the error for handling in the calling code
      throw error;
    });
};
