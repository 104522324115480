import React from "react";
import { NavLink } from "react-router-dom";

export const Services = () => {
  return (
    <section id="services" className="services section-bg mt-4">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2 className="mt-4">Our Services</h2>
          <p className="mt-4">Check out the great services we offer</p>
        </div>
        <div>
          <p className="fs-4 text">
            Welcome to 2Koders Private Limited, your one-stop destination for
            comprehensive IT solutions tailored to elevate your digital
            presence. With expertise spanning across Android app development,
            iOS app development, website design, and robust ERP solution
            management, we are dedicated to meeting all your technological needs
            under one roof.
          </p>
          <p className="fs-4 text">Android & iOS App Development:</p>
          <p className="fs-5 text">
            Harness the power of mobile technology with our Android and iOS app
            development services. Whether you're looking to engage your audience
            with a captivating mobile experience or streamline your business
            processes with a powerful app solution, our team utilizes
            cutting-edge technologies such as Flutter and React to deliver
            high-quality applications that are not only visually stunning but
            also functionally exceptional.
          </p>

          <p className="fs-4 text">Website Development:</p>
          <p className="fs-5 text">
            Make a lasting impression with a visually striking and user-friendly
            website. Our website development services are designed to help you
            stand out in the digital landscape. From responsive design to
            seamless navigation, we leverage frameworks like Laravel and Node.js
            to create websites that not only look great but also perform
            flawlessly across devices and platforms.
          </p>
          <p className="fs-4 text">ERP Solution Management:</p>
          <p className="fs-5 text">
            Optimize your business operations with our ERP solution management
            services. From inventory management to financial tracking, our
            robust ERP systems integrate seamlessly to provide you with
            real-time insights and unparalleled control over every aspect of
            your business. With technologies like ASP.NET, we tailor our
            solutions to your specific business needs, ensuring efficiency,
            scalability, and growth.
          </p> <p className="fs-4 text">Graphic Design Services:</p>
          <p className="fs-5 text">
          Transform your brand's visual identity with our professional graphic design services. From captivating logos and branding materials to eye-catching marketing collateral, we specialize in creating impactful visuals that leave a lasting impression on your audience.
          </p>
        </div>
        <div className="row mt-4 justify-content-center servicesRow">
          <div
            className="col-md-6 col-lg-3 d-flex align-items-stretch"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div className="icon-box">
              <div className="icon">
                <i className="bx bxl-dribbble"></i>
              </div>
              <h4 className="title">
                <NavLink to="" className="text-danger">
                  Android & iOS App Development
                </NavLink>
              </h4>
              <p className="description">
                Crafting captivating mobile experiences for Android and iOS
                platforms. From intuitive design to seamless performance, we
                deliver tailored solutions that engage users and drive results.
              </p>
            </div>
          </div>

          <div
            className="col-md-6 col-lg-3 d-flex align-items-stretch"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div className="icon-box">
              <div className="icon">
                <i className="bx bx-file"></i>
              </div>
              <h4 className="title">
                <NavLink to="" className="text-danger">
                  Web Development
                </NavLink>
              </h4>
              <p className="description">
                We build engaging websites that drive results. From sleek
                designs to seamless functionality, trust us to bring your online
                vision to life
              </p>
            </div>
          </div>

          <div
            className="col-md-6 col-lg-3 d-flex align-items-stretch"
            data-aos="zoom-in"
            data-aos-delay="400"
          >
            <div className="icon-box">
              <div className="icon">
                <i className="bx bx-world"></i>
              </div>
              <h4 className="title">
                <NavLink to="" className="text-danger">
                  ERP Solutions
                </NavLink>
              </h4>
              <p className="description">
                Optimize your business processes with our tailored ERP
                solutions. From inventory management to financial tracking, we
                provide seamless integration and real-time insights for enhanced
                efficiency and growth.
              </p>
            </div>
          </div>

          <div
            className="col-md-6 col-lg-3 d-flex align-items-stretch"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div className="icon-box">
              <div className="icon">
                <i className="bx bxl-dribbble"></i>
              </div>
              <h4 className="title">
                <NavLink to="" className="text-danger">
                  Graphics Designing
                </NavLink>
              </h4>
              <p className="description">Elevate your brand with our expert graphic design. From logos to marketing materials, we create stunning visuals that captivate and inspire.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
