import React, { useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

const Carousel = ({ projects }) => {
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);

  if (!projects || projects.length === 0) {
    return <div>No projects to display</div>;
  }

  const nextSlide = () => {
    setCurrentProjectIndex((prevIndex) =>
      prevIndex === projects.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentProjectIndex((prevIndex) =>
      prevIndex === 0 ? projects.length - 1 : prevIndex - 1
    );
  };

  const openNewWindow = () => {
    window.open(projects[currentProjectIndex].url, "_blank");
  };

  return (
    <div className="carousel">
      <button className="prev" onClick={prevSlide}>
        <FiChevronLeft />
      </button>
      <div className="project">
        <img
          src={projects[currentProjectIndex].image}
          alt="project-img"
          className="image pt-4"
          onClick={openNewWindow} // Add onClick event handler to open new window.
        />
        <div className="project-details text-light">
          <h2>{projects[currentProjectIndex].name}</h2>
          <p>{projects[currentProjectIndex].details}</p>
        </div>
      </div>
      <button className="next" onClick={nextSlide}>
        <FiChevronRight />
      </button>
    </div>
  );
};

export default Carousel;
