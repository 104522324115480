import React, { useState } from "react";
import Endpoints from "../Endpoints";
import { get, post } from "../AjaxCalls";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "emailjs-com";

export const Contact = () => {
  const [formData, setFormData] = useState({
    ContactName: "",
    ContactNumber: "",
    EmailID: "",
    Message: "",
  });

  // useEffect(() => {
  //   getContactList();
  // }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (
      !/^\d*$/.test(value) &&
      value.length <= 12 &&
      name === "ContactNumber"
    ) {
      return false;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      formData.ContactName === "" ||
      formData.ContactNumber === "" ||
      formData.EmailID === "" ||
      formData.Message === ""
    ) {
      toast.error("Please enter data in required fields.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return false;
    }
    AddContact();
    sendMail(e);
  };

  const clearForm = () => {
    setFormData({
      ContactName: "",
      ContactNumber: "",
      EmailID: "",
      Message: "",
    });
  };

  const AddContact = () => {
    post(Endpoints.SAVE_CONTACT_LIST, formData)
      .then((data) => {
        toast.success("Inquiry submitted successfully!", {
          position: toast.POSITION.TOP_CENTER,
        });
        console.log("Data added successfully", data);
        // getContactList();
        clearForm();
      })
      .catch((error) => {
        console.error("Error adding data", error);
      });
  };

  const sendMail = (e) => {
    const emailContent = {
      from_name: formData.ContactName + " - " + formData.ContactNumber,
      name: formData.ContactName + " - " + formData.ContactNumber,
      reply_to: "contact@2koders.com",
      message: formData.Message,
    };

    emailjs
      .send(
        "service_mvu7f5n",
        "template_fvoha9e",
        emailContent,
        "tJpFwrrhXwBmbzm3J"
      )
      .then(
        (result) => {
          // console.log("Email sent successfully:", result.text);
          // toast.success("Inquiry submitted successfully!", {
          //   position: toast.POSITION.TOP_CENTER,
          // });
          clearForm();
        },
        (error) => {
          console.error("Error sending inquiry:", error.text);
        }
      );

    // emailjs
    //   .send(
    //     "service_72vh3p1",
    //     "template_fvoha9e",
    //     emailData,
    //     "tJpFwrrhXwBmbzm3J"
    //   )
    //   .then(
    //     (result) => {
    //       toast.success("Inquiry submitted successfully!", {
    //         position: toast.POSITION.TOP_CENTER,
    //       });
    //       // console.log("Email sent successfully:", result.text);
    //       clearForm();
    //     },
    //     (error) => {
    //       console.error("Error sending inquiry:", error.text);
    //     }
    //   );
  };

  // eslint-disable-next-line no-unused-vars
  const getContactList = () => {
    get(Endpoints.GET_CONTACT_LIST)
      .then((data) => {
        console.log("Data Fetched successfully:", data);
      })
      .catch((error) => {
        console.error("Error Fetching data:", error);
      });
  };

  return (
    <>
      <ToastContainer />
      <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <p>Contact us the get started</p>
          </div>

          <div className="row">
            <div
              className="col-lg-5 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="info">
                <div className="address">
                  <i className="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p>
                    Crystal IT PARK STP-1, Ground Floor, IT/ITES- SEZ, Khandwa
                    Rd, Indra Puri Colony, near Bhawar Kuwan Square, Indore,
                    Madhya Pradesh 452001
                  </p>
                </div>

                <div className="email">
                  <i className="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p>contact@2koders.com</p>
                </div>

                <div className="phone">
                  <i className="bi bi-phone"></i>
                  <h4>Call:</h4>
                  <p>+91 8461996117</p>
                </div>

                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621"
                  frameBorder="0"
                  style={{ border: 0, width: "100%", height: "290px" }}
                  allowFullScreen
                  title="Google Maps"
                ></iframe>
              </div>
            </div>

            <div
              className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <form onSubmit={handleSubmit} className="php-email-form">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label for="name">Your Name</label>
                    <input
                      type="text"
                      name="ContactName"
                      className="form-control"
                      placeholder="Your Name"
                      value={formData.ContactName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group col-md-6 mt-3 mt-md-0">
                    <label for="name">Your Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="EmailID"
                      placeholder="Your Email"
                      value={formData.EmailID}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <label for="name">Contact Number</label>
                  <input
                    type="text"
                    className="form-control"
                    name="ContactNumber"
                    placeholder="Contact Number"
                    value={formData.ContactNumber}
                    onChange={handleInputChange}
                    maxLength={12}
                  />
                </div>
                <div className="form-group mt-3">
                  <label for="name">Message</label>
                  <textarea
                    className="form-control"
                    name="Message"
                    rows="10"
                    value={formData.Message}
                    onChange={handleInputChange}
                  ></textarea>
                </div>
                <div className="text-center">
                  <button type="submit">Send Message</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <Whatsaap /> */}
      </section>
    </>
  );
};
